import logo from './logo.svg';

import './App.css';
import { Container, Typography, Grid, ThemeProvider, useTheme, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, IconButton, Box, CardMedia } from '@mui/material';


// Header component
const Header = () => {
  return (
    <header>
      {/* <h1> majulah.io</h1> */}
      {/* <p>Invigorating collaboration. Transcending self-limiting social norms and naysayers. Unleashing human potential. </p> */}

      <Typography variant='h3' mt={-10}>
        majulah.io
      </Typography>

      <Typography variant='h5' mt={2}>
        a social collaboration engine for fostering ground-up initiatives
      </Typography>

      <Typography variant='body1' mt={2} mb={2}>
        We accelerate the attainment of United Nations Sustainable Development Goals  &nbsp;





        <Typography
          variant="body1"

          sx={{ color: '#61dafb' }}
          component="a"

          href="https://sdgs.un.org/goals"
        >
          [https://sdgs.un.org/goals]
        </Typography>

        &nbsp; covering Decent Work and Economic Growth;  Industry,  Innovation, and Infrastructure;  Sustainable Cities and Communities.
      </Typography>

    </header>
  );
};

// Features component
const Features = () => {
  return (
    <section className="features">
      {/* <div className="feature">
        <h2>Speak Up Freely</h2>
        <p> No risk of being judged or mislabeled. Prioritize your privacy. Initiatives (Ideas) are weighed by merits rather than from whom. </p>
      </div>
      <div className="feature">
        <h2>Leverage Ideas and Expertise Across Silos Any Time Any Where</h2>
        <p>24 x 7 virtual brainstorming that can be shared outside your existing network to get more diverse ideas and faster resolution. Great for people with a growth mindset.</p>
      </div>
      <div className="feature">
        <h2>Earn Recognitions</h2>
        <p>Depending on how active you are and how impactful your contribution is to the community, you could earn recognitions that can open more doors for your career.</p>
      </div> */}
    </section>
  );
};

// Call To Action (CTA) component
// const CTA = () => {
//   return (
//     <section className="cta">
//       <h2>Ready to get started?</h2>
//       <p>Sign up now as a beta user!</p>
//       <button>Sign Up</button>
//     </section>
//   );
// };

const CTA = () => {
  return (
    <Typography
      variant="body1"

      sx={{ color: '#61dafb' }}
      component="a"
      // href="https://forms.gle/92ycEFb94KcKsvAn6"
      href="https://forms.gle/qRjrkpjEBUxWodLs5"
    >
      Sign up as a Beta User
    </Typography>
  );
};





function App() {
  return (
    <div className='App'>
      <div className='App-header'>
        <Container>

          {/* <Box>
            <img src="/BoosterTrim.jpg" />
          </Box> */}


          <CardMedia
            component="img"
            height="194"
            image="/Booster.jpg"
            alt="picture"
            style={{
              objectFit: 'cover',
              height: '100%',
              objectPosition: ' top',
              clipPath: 'inset(0 0 20% 0)',
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://myx048upload105057-dev.s3.amazonaws.com/public/dolphin.jpg";
            }} // this give the default image when the value call from the database is null
          />



          <Header />
          <Features />
          <CTA />
        </Container>
      </div>
    </div>
  );
}

export default App;
